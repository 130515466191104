<template>
    <span v-if="time !== '00:00'" class="font-semibold">{{ time }} {{$t('sessionExpirationLabel')}}</span>
    <span v-else class="font-semibold text-red-500">{{$t('sessionExpirationErrorText')}}</span>
</template>

<script>
import * as moment from 'moment/moment'

export default {
    name: 'Counter',
    data () {
        return {
            /** Set the counter. */
            date: moment(60 * 10 * 1000)
        }
    },
    computed: {
        /** Computed method for formating counter. */
        time () {
            return this.date.format('mm:ss')
        }
    },
    mounted () {
        let timesRun = 0
        /** Activate counter on page mounted. */
        const timer = setInterval(() => {
            this.date = moment(this.date.subtract(1, 'seconds'))
            timesRun++
            if (timesRun === 600) {
                clearInterval(timer)
                this.$emit('disabled')
            }
        }, 1000)
    }
}
</script>
