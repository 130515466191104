<template>
    <div class="mb-30 md:mb-36">
        <h1 class="text-2xl font-titillium-bold text-gray-800 opacity-85 tracking-tight mb-10">{{title}}</h1>
        <p class="small text-gray-800 opacity-85 tracking-tight">{{subtitle}}</p>
    </div>
</template>
<script>
export default {
    name: 'FormTitle',
    props: ['title', 'subtitle']
}
</script>
