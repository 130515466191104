<template>
    <div class="max-w-sm mx-auto mt-257 px-12 md:px-0">
        <Title
            :title="$t('loginCodeFormTitle')"
            :subtitle="$t('loginCodeFormSubtitle')"
        />

        <!-- General error message -->
        <p
            v-if="error"
            class="text-red-500 small my-10"
        >
            {{error}}
        </p>

        <form
            @submit.prevent="onSubmit"
            ref="loginValidateCodeForm"
        >
            <Input
                v-on:input="onInput"
                class="mb-22"
                :label="$t('securityCodeFieldLabel')"
                :placeholder="$t('securityCodeFieldPlaceholder')"
                type="password"
                name="securityCode"
            />

            <p class="mb-37">
                <Counter @disabled="disabledExpiration = true"/>
            </p>

            <div class="w-full flex justify-center mb-36">
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600"
                    :disabled="disabledExpiration ? disabledExpiration : disabled || loginValidateLoading"
                    role="submit"
                    :text="$t('loginCodeFormButtonText')"
                    :spinning="loginValidateLoading"
                />
            </div>
        </form>
    </div>
</template>

<script>
import Input from '@/components/form/Input'
import Button from '@/components/buttons/Button'
import Title from '@/components/texts/FormTitle'
import { mapGetters, mapActions } from 'vuex'
import Counter from '@/components/counter/Counter'

export default {
    name: 'LoginValidate',

    components: {
        Input,
        Button,
        Title,
        Counter
    },

    data () {
        return {
            disabled: true,
            disabledExpiration: false,

            loginValidateLoading: false
        }
    },

    computed: {
        /** Get vuex state */
        ...mapGetters(['errors', 'error', 'email', 'password', 'token', 'userType'])
    },

    methods: {
        /** Vuex actions. */
        ...mapActions(['loginValidate']),

        onInput (event) {
            if (event.length === 6) this.disabled = false
        },

        onSubmit () {
            this.loginValidateLoading = true

            this.loginValidate({
                email: this.email,
                password: this.password,
                sms_code: this.$refs.loginValidateCodeForm.securityCode.value
            })
                .then(() => {
                    this.loginValidateLoading = false
                })
        }
    }
}
</script>
